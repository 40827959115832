import { openConfirmPopup, openContentPopup } from '../../widgets/popup'

$(() => {
  const selectedOptions = {}

  function reLoadPriceTable() {
    const table = $('#priceTable')
    if (table.length) {
      const items = $('.constructor-options').find('select,input')
      $.get('/constructor/prices/', items.serialize(), function (result) {
        table.html(result)
      })
    }
  }

  function toggleBulkSides(val) {
    const singleWrapper = $('.single-upload-wrapper')
    const bulkWrapper = $('.bulk-upload-wrapper')

    if (!singleWrapper.length) {
      return
    }

    function toggleSubmitUrl() {
      const form = singleWrapper.closest('form')

      form.prop('action', form.data('url'))
      form.data('url', form.prop('action'))
    }

    if (val === 1) {
      singleWrapper.find('.file-link').val('')
      singleWrapper.hide()
      bulkWrapper.show()
    } else {
      bulkWrapper.find('.file-link').val('')
      bulkWrapper.hide()
      singleWrapper.show()
    }

    toggleSubmitUrl()
  }

  function checkStepValue(e) {
    const val = $(this).val()
    const step = window.sizeConfig ? parseInt(window.sizeConfig.step) : null

    if (!step) {
      return
    }
    if (step === 1) {
      return $(this).val(Math.floor(val))
    }
    if (val.indexOf('.') === -1) {
      return
    }

    const fraction = val - Math.floor(val)
    const result = step * Math.ceil(fraction / step)

    $(this).val((Math.floor(val) + result).toFixed(3))
  }

  function checkSizeValues(e) {
    const width = $('input[name=width]')
    const height = $('input[name=height]')
    const units = $('input[name=units]:checked').val()
    const config = window.sizeConfig || null
    if (!config) {
      return
    }
    if (width.val() < config[units].width.min) {
      width.val(config[units].width.min)
    }
    if (height.val() < config[units].height.min) {
      height.val(config[units].height.min)
    }
    const min = Math.min(config[units].width.max, config[units].height.max)
    const max = Math.max(config[units].width.max, config[units].height.max)

    if (width.val() < min && height.val() < min) {
      return
    }
    if (width.val() > max && height.val() > max) {
      width.val(config[units].width.max)
      return height.val(config[units].height.max)
    }

    if (width.val() > min) {
      if (height.val() > min) return setMinValue(width, min, config.paneling)
      if (width.val() > max) return width.val(max)
    } else {
      if (width.val() > min) return setMinValue(height, min)
      if (height.val() > max) return height.val(max)
    }
  }

  let messageWasDisplayed = false

  function showLimitDialog(size) {
    if (messageWasDisplayed) {
      return true
    }
    const message =
      'Maximum single piece width for this product is ' +
      size +
      ' inches.<br/> If you require larger size, please panel and upload your<br/> files at ' +
      size +
      ' inches wide or less'
    openContentPopup(message)

    messageWasDisplayed = true
  }

  function onSizeChanged() {
    checkStepValue.call(this)
    checkSizeValues.call(this)
    reLoadPriceTable()
  }

  function onAmountChanged(e) {
    const el = e.target
    if (!el.min) {
      return
    }
    if (parseInt(el.value) < parseInt(el.min)) {
      el.value = el.min
    }
  }

  function onSelectChanged() {
    const self = $(this)
    const id = self.data('id')
    const showAlert = self.data('alert')
    const option = self.children(':selected')
    const isCart = self.closest('#changeItemOptions').length > 0
    const hint = $(option).data('hint')
    if (self.data('skipChangeHandler')) {
      return
    }
    if (isCart) {
      return filterParentOptions()
    }
    if (hint) {
      openContentPopup(hint)
    }

    const notAvailable = collectUnavailableOptions()
    if (notAvailable.length && showAlert) {
      const prev = self.find('option[value=' + selectedOptions[id] + ']').html()
      const content =
        notAvailable.join(', ') + ' are only available with ' + prev

      return openConfirmPopup(
        content,
        function () {
          selectedOptions[id] = self.val()
          filterParentOptions()
        },
        function () {
          self.data('skipChangeHandler', true)
          self.val(selectedOptions[id]).trigger('change')
          self.data('skipChangeHandler', false)

          reLoadPriceTable()
        },
        this
      )
    }
    selectedOptions[id] = self.val()
    filterParentOptions()
  }

  function setMinValue(el, val, isPaneling) {
    if (isPaneling) {
      showLimitDialog(val)
    }
    el.val(val)
  }

  function filterParentOptions() {
    const items = $('.constructor-options').find('select,input')
    const params = items.serialize()
    //items.prop('disabled', 'disabled');
    $.get('/constructor/options/', params, function (result) {
      //items.prop('disabled', false);
      $('#featuresOptions').html(result)
    })
  }

  function collectUnavailableOptions() {
    const result = []
    const options = $('.constructor-options option:selected')
      .map(() => parseInt($(this).val()))
      .toArray()

    $('.constructor-options select').each(function () {
      const selected = $(this).val()
      $(this)
        .find('option')
        .each(function () {
          if (selected !== this.value) {
            return
          }

          const parent = $(this).data('parents')
          if (!parent) {
            return
          }
          const list = parent.toString().split(',')
          let isAvailable = false

          for (const item of list) {
            if (options.indexOf(parseInt(item)) !== -1) {
              isAvailable = true
            }
          }
          if (!isAvailable) {
            result.push($(this).html())
          }
        })
    })
    return result
  }

  $(document).on(
    'change',
    '.constructor-options select:not([name=package]), input:not([name=units],[name=height],[name=width])',
    reLoadPriceTable
  )

  $(document).on('change', '.design-sides', function () {
    const backSide = $('.back-file')
    const val = parseInt($(this).val())
    val === 1 ? backSide.hide() : backSide.show()
    toggleBulkSides(val)
  })

  $(document).on('change', 'input[name=units]', function () {
    const $w = $('input[name=width]')
    const $h = $('input[name=height]')
    if ($(this).val() === 'feet') {
      $w.val($w.val() < 12 ? 1 : Math.round($w.val() / 12))
      $h.val($h.val() < 12 ? 1 : Math.round($h.val() / 12))
      return true
    }
    $w.val($w.val() >= 1 ? $w.val() * 12 : 1)
    $h.val($h.val() >= 1 ? $h.val() * 12 : 1)
  })

  $('.constructor-options select').each(function () {
    const self = $(this)
    selectedOptions[self.data('id')] = self.val()
  })

  $(document).on('change', '.constructor-options select', onSelectChanged)
  $(document).on('change', '.constructor-size [name=width]', onSizeChanged)
  $(document).on('change', '.constructor-size [name=height]', onSizeChanged)
  $(document).on('change', '.constructor-amount [name=amount]', onAmountChanged)
})
