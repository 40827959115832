const { openContentPopup } = require('./widgets/popup')
const { debounce } = require('./functions')

const {
  basename,
  createCookie,
  getCookie,
  showStatusMessage,
} = require('./functions')

import '../scss/styles.scss'

$(() => {
  $.validator.setDefaults({
    focusInvalid: false,
    invalidHandler: function (form, validator) {
      const errors = validator.errorList ?? []
      if (!errors.length) {
        return true
      }
      let $fe
      for (let i in errors) {
        const $el = $(errors[i].element)
        $fe = $el
        if ($el.is(':radio') || $el.is(':checkbox')) {
          if ($el.parent()[0].tagName === 'LABEL') {
            $fe = $el.parent()
          }
        }
        $fe
          .caption(errors[i].message, { align: 'right', color: 'red' })
          .addClass('c-red')
      }
      $fe && $fe.focus()
    },
    showErrors: function () {
      const list = this.successList ?? []
      for (const item of list) {
        $(item).removeClass('c-red').caption(':remove')
        $(item).parent().removeClass('c-red')
      }
    },
  })

  $(document).on('submit', 'form', function () {
    return $(this).valid()
  })

  $('.phone').mask('(999) 999-9999')

  $('.digits').keypress(function (e) {
    if (e.which !== 8 && e.which !== 0 && (e.which < 48 || e.which > 57)) {
      return false
    }
  })

  $('.flash-notice').each(function (i, o) {
    showStatusMessage($(o).html(), $(o).data('type'))
  })

  $('.tabs > a').on('click', function () {
    const tab = $(this).data('tab')
    const $self = $(this)

    $self.closest('.tabs').find('a').removeClass('active')
    $self.addClass('active')

    const $tab = $(tab)

    if ($tab.length) {
      $('.tab-content').hide()
      $tab.show()
    }
  })

  $('.js-slide-to').on('click', function (e) {
    e.preventDefault()
    const target = $(this).data('target')

    if (target) {
      $('html, body').animate(
        {
          scrollTop: $(target).offset().top,
        },
        500
      )
    }
  })

  $('.js-toggle').on('click', function (e) {
    e.preventDefault()

    const targets = ($(this).data('target') ?? '').split(',')
    for (let target of targets) {
      $(target).toggle()
    }
  })

  $(document).on('change', '.custom-file input[type=file]', function () {
    const $span = $(this).closest('.custom-file').find('span')
    const file = $(this).val()
      ? basename($(this).val())
      : $span.data('placeholder')

    if ($span.length) {
      $span.removeClass('uploaded').html(file)
    }
  })

  $('.logo-upload-file input:file').on('change', function () {
    const img = '<img src="/img/icon/plus.png" width="20">'
    const a = $(this).parent().find('a')

    const text = $(this).val() ? basename($(this).val()) : 'Select logo file'
    a.html(img + text)
  })

  $(document).on('change', '.package-chooser', function () {
    const hidden = $(this).closest('.custom-select').find('input:hidden')
    if (hidden.length) {
      hidden.val($(this).val()).trigger('change')
    }
  })

  const $lightSlider = $('#lightSlider')
  if ($lightSlider.length) {
    $lightSlider.lightSlider({
      item: 1,
      auto: false,
      pause: 5000,
      pauseOnHover: true,
      gallery: true,
      pager: true,
      controls: false,
      thumbItem: 6,
      thumbMargin: 5,
    })
  }

  if ($(document).width() > 1200) {
    $('.zoomple').zoomple({
      offset: { x: 0, y: 0 },
      showOverlay: true,
      roundedCorners: false,
      windowPosition: { x: 'right', y: 'top' },
      zoomWidth: 300,
      zoomHeight: 300,
      attachWindowToMouse: false,
    })
  }

  const RESTRICTED_COOKIE = 'restricted_key'
  const $restrictedMessage = $('.restricted-message')
  if ($restrictedMessage.length && getCookie(RESTRICTED_COOKIE) === null) {
    createCookie(RESTRICTED_COOKIE, 1, 60)
    openContentPopup($restrictedMessage.html())
  }

  window.addEventListener(
    'error',
    debounce(function (e) {
      const data = {
        message: e.message,
        filename: e.filename,
        lineno: e.lineno,
        colno: e.colno,
        stack: e.error && e.error.stack,
        type: e.type,
        timestamp: new Date().toISOString(),
        userAgent: navigator.userAgent,
        url: window.location.href,
        viewportWidth: window.innerWidth,
        viewportHeight: window.innerHeight,
        referrer: document.referrer,
      }

      $.post('/ajax/logger/', data)

      return false
    }, 1000),
    true
  )
})
