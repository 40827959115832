const { showSuccessMessage, showErrorMessage } = require('../../functions')

$(() => {
  let selectedFiles = {}
  const root = '.single-upload '
  const files = $(root + 'input:file')
  const front = $(root + '.front-file')
  const back = $(root + '.back-file')
  const dropBox = $(root + '.drop-files')
  const frontName = front.find('.drop-files-file')
  const backName = back.find('.drop-files-file')
  const frontFile = front.find('input:file')
  const backFile = back.find('input:file')
  const globalAmount = $('input[name=amount]')
  const cartBtn = $(root + '[name=doAddFile]')
  const progressBar = $(root + '.progress-bar div')
  const uploadHelp = $(root + '.file-upload-help')
  const uploadStatus = $(root + '.upload-status')
  const readyItems = $(root + '.upload-ready-items')
  const linkSubmit = $(root + '.link-upload input:submit')
  const startUploadBtn = $(root + '.file-upload .start-upload')

  function toggle() {
    ;(function toggleReadyItems() {
      readyItems.hide()
      cartBtn.parent().hide()
      if (readyItems.find('tr').length) {
        readyItems.show()
        cartBtn.parent().show()
      }
    })()
    ;(function toggleFrontIcon() {
      front.removeClass('js-ready')
      frontName.html('')
      if (selectedFiles.front) {
        front.addClass('js-ready')
        frontName.html(selectedFiles.front.name)
      }
    })()
    ;(function toggleBackIcon() {
      back.removeClass('js-ready')
      backName.html('')
      if (selectedFiles.back) {
        back.addClass('js-ready')
        backName.html(selectedFiles.back.name)
      }
    })()
    ;(function toggleStartUploadBtn() {
      if (selectedFiles.front && selectedFiles.back) {
        return startUploadBtn.removeAttr('disabled')
      }
      startUploadBtn.attr('disabled', 'disabled')
    })()
  }

  function toggleLinkSubmit() {
    if ($(this).val()) {
      return linkSubmit.removeAttr('disabled')
    }
    linkSubmit.attr('disabled', 'disabled')
  }

  function stopDefaultEvents(e) {
    e.preventDefault()
    e.stopPropagation()
  }

  function handleUploadStart() {
    front.addClass('js-busy')
    frontFile.attr('disabled', 'disabled')

    back.addClass('js-busy')
    backFile.attr('disabled', 'disabled')

    uploadHelp.hide()
    uploadStatus.show()
  }

  function handleUploadFinish() {
    selectedFiles = {}

    front.removeClass('js-busy')
    frontFile.val('').removeAttr('disabled')

    back.removeClass('js-busy')
    backFile.val('').removeAttr('disabled')

    uploadHelp.show()
    uploadStatus.hide()

    toggle()
  }

  function isValidExt(name) {
    const ext = name.split('.').pop().toLowerCase()

    return sizeConfig.extensions.indexOf(ext) !== -1
  }

  function addCartItem() {
    const self = this
    const id = $(this).data('id')
    const amount = $('#' + id + 'amount').val()
    const frontId = $('#' + id + 'front').val()
    const backId = $('#' + id + 'back').val()
    const data = $('.constructor-options').find('select,input').serialize()
    const params =
      data +
      '&' +
      $.param({ amount: amount, frontFileId: frontId, backFileId: backId })

    function flushItem(result) {
      if (result.error) {
        return showErrorMessage(result.error)
      }
      if (result.success) {
        self.closest('tr').remove()
        toggle()
        return showSuccessMessage('Design was added')
      }
      return showErrorMessage('An error was occured')
    }

    $.post('/cart/add/', params, flushItem, 'json')
  }

  function setSelectedFile(e) {
    const files =
      e.target.files && e.target.files.length
        ? e.target.files
        : e.originalEvent.dataTransfer
          ? e.originalEvent.dataTransfer.files
          : []
    const side =
      $(e.target).prop('id') || $(e.target).closest('.drop-files').prop('id')
    const file = files[0]
    selectedFiles[side] = null
    if (file) {
      if (!isValidExt(file.name)) {
        showErrorMessage(file.name + ': unsupported file format')
      } else {
        selectedFiles[side] = file
      }
    }
    toggle()
  }

  function removeUploadedFiles() {
    const inputs = $(this).closest('tr').find('input:hidden')
    const removed = []
    inputs.each(function () {
      const id = $(this).val()
      if (removed.indexOf(id) === -1) {
        $.getJSON('/file/remove/', { id: id })
        removed.push(id)
      }
    })
    $(this).closest('tr').remove()
    toggle()
  }

  function uploadUserFiles() {
    function loadProgress(e) {
      const percent = (100 / e.total) * e.loaded

      progressBar.animate({ width: percent + '%' }, 50)
    }

    function loadComplete() {
      if (xhr.readyState !== 4 || xhr.status !== 200) {
        return
      }
      const response = JSON.parse(xhr.responseText)
      if (response.preview) {
        readyItems.append(response.preview)
      }
      if (response.error) {
        showErrorMessage(response.error)
      }
      toggle()
    }

    if ($(this).attr('disabled')) {
      return false
    }
    handleUploadStart()

    const xhr = new XMLHttpRequest()
    xhr.addEventListener('loadend', handleUploadFinish)
    xhr.addEventListener('readystatechange', loadComplete)
    xhr.upload.addEventListener('progress', loadProgress)

    const data = new FormData()
    data.append('front', selectedFiles.front)
    data.append('back', selectedFiles.back)
    data.append('product', sizeConfig.product)
    data.append('amount', globalAmount.val())
    xhr.open('POST', '/file/double/')
    xhr.send(data)
  }

  $('.single-upload').on('keyup', '.link-upload input:text', toggleLinkSubmit)
  $('.single-upload').on('click', '.js-cart-add', addCartItem)
  $('.single-upload').on('click', '.js-remove', removeUploadedFiles)
  $('.single-upload').on('click', '.start-upload', uploadUserFiles)

  files.on('change', setSelectedFile)

  dropBox.on(
    'drag dragstart dragend dragover dragenter dragleave drop',
    stopDefaultEvents
  )
  dropBox.on('drop', setSelectedFile)
})
