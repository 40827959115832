const { showErrorMessage, showSuccessMessage } = require('../../functions')

$(() => {
  let selectedFiles = {}
  const root = '.set-upload '
  const sides = ['front', 'back', 'box']
  const dropBox = $(root + '.drop-files')
  const frontAsBack = $(root + '#frontAsBack')
  const cartBtn = $(root + '[name=doAddFile]')
  const progressBar = $(root + '.progress-bar div')
  const uploadHelp = $(root + '.file-upload-help')
  const uploadStatus = $(root + '.upload-status')
  const readyItems = $(root + '.upload-ready-items')
  const globalAmount = $('input[name=amount]')
  const startUploadBtn = $(root + '.file-upload .start-upload')

  function toggle() {
    ;(function toggleReadyItems() {
      readyItems.hide()
      cartBtn.parent().hide()
      if (readyItems.find('tr').length) {
        readyItems.show()
        cartBtn.parent().show()
      }
    })()
    ;(function toggleSideIcon() {
      $.each(sides, function (i, side) {
        const el = $(root + '.' + side + '-file')
        const span = el.find('.drop-files-file')
        const checkbox = el.find('input:checkbox')
        const isChecked = checkbox.length && checkbox.is(':checked')
        span.html('')
        el.removeClass('js-ready')
        if (selectedFiles[side] || isChecked) {
          el.addClass('js-ready')
          if (!isChecked && selectedFiles[side]) {
            span.html(selectedFiles[side].name)
          }
        }
      })
    })()
    ;(function toggleStartUploadBtn() {
      startUploadBtn.removeAttr('disabled')
      $.each(sides, function (i, side) {
        const el = $(root + '.' + side + '-file')
        if (!el.is(':visible')) return

        if (!el.hasClass('js-ready')) {
          startUploadBtn.attr('disabled', 'disabled')
        }
      })
    })()
  }

  function toggleBoxImprint() {
    const box = $(root + '.box-file')
    const text = $(this).find('option:selected').html()

    box.hide()
    if (text !== 'none') {
      box.show()
    }
  }

  function stopDefaultEvents(e) {
    e.preventDefault()
    e.stopPropagation()
  }

  function handleUploadStart() {
    $.each(sides, function (i, side) {
      const el = $(root + '.' + side + '-file')
      const file = el.find('input:file')
      const checkbox = el.find('input:checkbox')
      el.addClass('js-ready')
      file.attr('disabled', 'disabled')
      if (checkbox.length) {
        checkbox.attr('disabled', 'disabled')
      }
    })

    uploadHelp.hide()
    uploadStatus.show()
  }

  function handleUploadFinish() {
    selectedFiles = {}

    $.each(sides, function (i, side) {
      const el = $(root + '.' + side + '-file')
      const file = el.find('input:file')
      const checkbox = el.find('input:checkbox')
      el.removeClass('js-ready')
      file.val('').removeAttr('disabled')
      if (checkbox.length) {
        checkbox.removeAttr('disabled').attr('checked', false)
      }
    })

    uploadHelp.show()
    uploadStatus.hide()

    toggle()
  }

  function isValidExt(name) {
    var ext = name.split('.').pop().toLowerCase()

    return sizeConfig.extensions.indexOf(ext) !== -1
  }

  function addCartItem() {
    const self = this
    const id = $(this).data('id')
    const amount = $('#' + id + 'amount').val()
    const frontId = $('#' + id + 'front').val()
    const backId = $('#' + id + 'back').val()
    const boxId = $('#' + id + 'box').val()
    const data = $('.constructor-options').find('select,input').serialize()
    const params =
      data +
      '&' +
      $.param({
        amount: amount,
        frontFileId: frontId,
        backFileId: backId,
        boxFileId: boxId,
      })

    function flushItem(result) {
      if (result.error) {
        return showErrorMessage(result.error)
      }
      if (result.success) {
        self.closest('tr').remove()
        toggle()
        return showSuccessMessage('Design was added')
      }
      return showErrorMessage('An error was occured')
    }

    $.post('/cart/add/', params, flushItem, 'json')
  }

  function setSelectedFile(e) {
    const files =
      e.target.files && e.target.files.length
        ? e.target.files
        : e.originalEvent.dataTransfer
          ? e.originalEvent.dataTransfer.files
          : []
    const side =
      $(e.target).prop('id') || $(e.target).closest('.drop-files').prop('id')
    const file = files[0]
    selectedFiles[side] = null
    if (file) {
      if (!isValidExt(file.name)) {
        showErrorMessage(file.name + ': unsupported file format')
      } else {
        selectedFiles[side] = file
      }
    }
    toggle()
  }

  function removeUploadedFiles() {
    const inputs = $(this).closest('tr').find('input:hidden')
    const removed = []
    inputs.each(function () {
      const id = $(this).val()
      if (removed.indexOf(id) === -1) {
        $.getJSON('/file/remove/', { id: id })
        removed.push(id)
      }
    })
    $(this).closest('tr').remove()
    toggle()
  }

  function uploadUserFiles() {
    function loadProgress(e) {
      const percent = (100 / e.total) * e.loaded

      progressBar.animate({ width: percent + '%' }, 50)
    }

    function loadComplete() {
      if (xhr.readyState !== 4 || xhr.status !== 200) {
        return
      }
      const response = JSON.parse(xhr.responseText)
      if (response.preview) {
        readyItems.append(response.preview)
      }
      if (response.error) {
        showErrorMessage(response.error)
      }
      toggle()
    }

    if ($(this).attr('disabled')) {
      return false
    }
    handleUploadStart()

    const xhr = new XMLHttpRequest()
    xhr.addEventListener('loadend', handleUploadFinish)
    xhr.addEventListener('readystatechange', loadComplete)
    xhr.upload.addEventListener('progress', loadProgress)

    const data = new FormData()
    const isChecked = frontAsBack.is(':checked')
    data.append('box', selectedFiles.box)
    data.append('front', selectedFiles.front)
    if (!frontAsBack.is(':checked') && selectedFiles.back) {
      data.append('back', selectedFiles.back)
    }
    data.append('product', sizeConfig.product)
    data.append('amount', globalAmount)
    data.append('isBackSame', isChecked ? 1 : 0)
    xhr.open('POST', '/file/double/')
    xhr.send(data)
  }

  $('.set-upload').on('change', '#frontAsBack', toggle)
  $('.set-upload').on('change', 'input:file', setSelectedFile)
  $('.set-upload').on('click', '.add-to-cart', addCartItem)
  $('.set-upload').on('click', '.remove-file', removeUploadedFiles)
  $('.set-upload').on('change', '.feature-box-imprint', toggleBoxImprint)

  $('.set-upload').on('click', '.file-upload .start-upload', uploadUserFiles)

  dropBox.on(
    'drag dragstart dragend dragover dragenter dragleave drop',
    stopDefaultEvents
  )
  dropBox.on('drop', setSelectedFile)
})
