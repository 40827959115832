$(() => {
  $('.file-checker .required').on('change', function () {
    const status = $(this)
      .closest('div:not(.file-helper):not(.custom-select)')
      .find('.valid-status')

    if (status.length) {
      $(this).val() ? status.addClass('valid') : status.removeClass('valid')
    }
  })
})
