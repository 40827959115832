function showCodeError(message) {
  const form = $('.code-form')
  const status = $('.code-status')

  form.fadeOut(200, function () {
    status.html(message).fadeIn(200)
    setTimeout(showCodeDefault, 2000)
  })
}

function showCodeDefault() {
  const notice = $('.codeNotice')
  const form = $('.code-form')
  const status = $('.code-status')

  if (notice.length) {
    if (notice.data('default')) {
      notice.html(notice.data('default'))
    }
  }

  status.fadeOut(200, function () {
    status.html('')
    form.fadeIn(200)
  })
}

$(() => {
  const cart = '#cart'
  const code = cart + ' .code'
  const general = cart + ' .general'

  $(document).on('submit', code, function (e) {
    e.preventDefault()

    function updateCart(data) {
      $('#cart').html(data)
    }

    $.get(
      '/cart/code/',
      $(this).serialize(),
      function (data) {
        if (data.error) {
          setTimeout(function () {
            $.get('/cart/update/', $(general).serialize(), updateCart)
          }, 2000)
          return showCodeError(data.error)
        }
        if (data.success) {
          $.get('/cart/update/', $(general).serialize(), updateCart)
        } else {
          return showCodeDefault()
        }
      },
      'json'
    )
  })

  $(document).on('keyup', code + ' input[type=text]', showCodeDefault)
})
