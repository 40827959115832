const { openPopupWindow, closePopupWindow } = require('../../widgets/popup')

function isEmptyQnt() {
  const items = collectItemQnt()
  const values = Object.values(items)
  if (!values.length) {
    return true
  }
  return values.some((value) => value === 0)
}

function collectItemQnt() {
  const qnt = '#cart .qnt'
  const items = {}
  $(qnt).each(function () {
    const id = $(this).data('id')
    const val = parseInt($(this).val()) || 0
    if (typeof items[id] === 'undefined') {
      items[id] = val
    } else {
      items[id] += val
    }
  })
  return items
}

function updateCart(data) {
  $('#cart').html(data)
}

$(() => {
  const cartRoot = '.cart'
  const cart = '#cart'
  const general = cart + ' .general'
  const qnt = cart + ' .qnt'

  $(document).on('submit', general, function (e) {
    e.preventDefault()
    $.get('/cart/update/', $(this).serialize(), updateCart)
  })

  const marks = $(cartRoot + ' .price.c-red')
  if (marks.length) {
    setTimeout(function () {
      marks.each(function () {
        $(this).hasClass('caption')
          ? $(this).fadeOut()
          : $(this).removeClass('c-red')
      })
    }, 5000)
  }

  $(document).on('change', qnt, function () {
    $(this).closest('form').trigger('submit')
  })

  $(document).on('click', cart + ' .remove', function () {
    if (!confirm('Are you sure?')) {
      return false
    }
    $.get('/cart/remove/', { id: $(this).data('id') }, updateCart)
  })

  $(document).on('click', cart + ' .checkItemQnt', function (e) {
    e.preventDefault()
    if (isEmptyQnt()) {
      return openPopupWindow('#emptyCartItems')
    }
    if ($(this).is('[disabled=disabled]')) {
      return false
    }
    return (window.location.href = $(this).attr('href'))
  })
  $(document).on('click', cart + ' #removeEmptyCartItems', function () {
    var items = collectItemQnt()
    if (!items) {
      return closePopupWindow('#emptyCartItems')
    }
    var list = []
    for (var id in items) {
      if (items[id] != 0) continue
      if (id === 'length' || !items.hasOwnProperty(id)) continue
      list.push(id)
    }
    if (list.length) {
      $.get('/cart/remove-bunch/', { id: list.join(',') }, updateCart)
    }
    return closePopupWindow('#emptyCartItems')
  })

  $(document).on('submit', '#changeItemOptions', function (e) {
    e.preventDefault()
    $.post($(this).prop('action'), $(this).serialize(), function (result) {
      updateCart(result)
      closePopupWindow('.options')
      $('#changeItemOptions').remove()
    })
  })
})
