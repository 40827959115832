const { showErrorMessage } = require('../functions')

$(() => {
  const toResetPassword = $('#toResetPassword')
  const toStartScreen = $('#toStartScreen')
  const registerFormCheckbox = $('.register-form input:checkbox')
  const recoverForm = $('.recover-form')

  function showStartScreen() {
    recoverForm.fadeOut(400, function () {
      $('.login-form').fadeIn(400)
    })
  }

  function showResetScreen() {
    $('.login-form').fadeOut(400, function () {
      recoverForm.fadeIn(400)
    })
  }

  function sendRecoverMail(e) {
    e.preventDefault()
    if (!$(this).valid()) {
      return false
    }
    $.post(
      '/auth/forgot/',
      $(this).serialize(),
      function (result) {
        if (result.errors) {
          return showErrorMessage(result.errors)
        }
        if (result.success) {
          $('.auth-status').html('please check your email')
          recoverForm.find('input').remove()
          return true
        }
        return showErrorMessage('An error was occurred')
      },
      'json'
    )
  }

  function toggleRegisterField() {
    const field = $(this).closest('dd').find('input:not([type=checkbox])')
    if ($(this).is(':checked')) {
      return field.attr('disabled', 'disabled')
    }
    return field.removeAttr('disabled')
  }

  toggleRegisterField.call(registerFormCheckbox)

  recoverForm.on('submit', sendRecoverMail)
  toStartScreen.on('click', showStartScreen)
  toResetPassword.on('click', showResetScreen)
  registerFormCheckbox.on('click', toggleRegisterField)
})
