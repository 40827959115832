$(() => {
  const customSelect = $('.custom-select')

  if (customSelect.length) {
    const $select = customSelect.find('select')
    $.each($select, function () {
      if ($(this).val() !== 0 && $(this).val() !== '') {
        $(this)
          .closest('.custom-select')
          .find('span')
          .html($(this).find('option:selected').text())
      }
    })
  }

  $(document)
    .on('change', '.custom-select select', function () {
      $(this).parent().find('span').html($('option:selected', this).html())
    })
    .on('focus', function () {
      $(this).closest('.custom-select').addClass('focused')
    })
    .on('blur', function () {
      $(this).closest('.custom-select').removeClass('focused')
    })
})
