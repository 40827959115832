const { showErrorMessage } = require('./../../functions')
document.addEventListener('DOMContentLoaded', function () {
  const submitButton = document.getElementById('card-field-submit-button')
  if (!submitButton) {
    return
  }

  if (!window.paypal || !window.paypal.CardFields || !window.paypal.Buttons) {
    return showErrorMessage(
      'Payment SDK not loaded. Please try again later or use another browser'
    )
  }

  const confirmCheckbox = document.getElementById('js-confirm')

  const csrf = {
    [$('meta[name="csrf-param"]').attr('content')]: $(
      'meta[name="csrf-token"]'
    ).attr('content'),
  }

  function triggerError(message) {
    showErrorMessage(message)

    submitButton.disabled = false
    submitButton.innerText = 'Pay now'
  }

  function handlePayPalError(err) {
    showErrorMessage('Error processing order payment. Please try again.')

    console.error(err)

    setTimeout(() => {
      window.location.reload()
    }, 2000)
  }

  function createOrderPayment() {
    const doSave = $('#doSaveCard').is(':checked') ? 1 : 0

    return new Promise((resolve, reject) => {
      $.post('/checkout/payment/', {
        ...csrf,
        doSave,
      })
        .done((res) => resolve(res.id))
        .fail((xhr, status, error) => reject(error))
    })
  }

  function approveOrderPayment(data) {
    const selectedUserCard = $('#savedCards').val()

    $.post(
      '/checkout/approve/',
      {
        ...csrf,
        orderId: data.orderID,
        selectedUserCard,
      },
      (res) => {
        if (res.error) {
          showErrorMessage(res.error)

          return setTimeout(() => {
            window.location.reload()
          }, 2000)
        }

        window.location.assign('/checkout/receipt/?order=' + res.order)
      }
    )
  }

  function submitWithSavedCard() {
    createOrderPayment().then((orderId) => {
      approveOrderPayment({ orderID: orderId })
    })
  }

  window.paypal
    .Buttons({
      style: {
        shape: 'pill',
        layout: 'vertical',
        color: 'silver',
        label: 'buynow',
      },
      onClick: function (data, actions) {
        if (!confirmCheckbox.checked) {
          showErrorMessage('Please agree to our Terms & Conditions')
          return actions.reject()
        }
        return actions.resolve()
      },
      createOrder: createOrderPayment,
      onApprove: approveOrderPayment,
      onCancel: () => {
        window.location.assign('/checkout/billing/')
      },
      onError: handlePayPalError,
    })
    .render('#paypal-button-container')

  const cardStyle = {
    input: {
      'font-size': '13px',
      'font-family': 'Roboto, sans-serif',
      color: '#3a3a3a',
      border: '1px solid #d7d7d7',
      'border-radius': '4px',
      padding: '10px 15px',
      'line-height': 'normal',
    },
  }

  const cardFields = window.paypal.CardFields({
    style: cardStyle,
    createOrder: createOrderPayment,
    onApprove: approveOrderPayment,
    onError: handlePayPalError,
  })

  if (cardFields.isEligible()) {
    cardFields
      .NameField({ placeholder: 'Cardholder Name' })
      .render('#card-name-field-container')
    cardFields.NumberField().render('#card-number-field-container')
    cardFields.CVVField().render('#card-cvv-field-container')
    cardFields.ExpiryField().render('#card-expiry-field-container')

    function submitCardFields() {
      submitButton.disabled = true
      submitButton.innerText = 'Processing...'

      const savedCard = Number($('#savedCards').val())

      if (savedCard) {
        return submitWithSavedCard()
      }

      cardFields.getState().then((state) => {
        if (!state.isFormValid) {
          return triggerError('Please fill out all fields')
        }
        if (!confirmCheckbox.checked) {
          return triggerError('Please agree to our Terms & Conditions')
        }
        const billingAddress = {
          addressLine1: document.getElementById('card-billing-address-line-1')
            .value,
          addressLine2: document.getElementById('card-billing-address-line-2')
            .value,
          region: document.getElementById('card-billing-address-state').value,
          locality: document.getElementById('card-billing-address-city').value,
          postalCode: document.getElementById(
            'card-billing-address-postal-code'
          ).value,
          countryCode: 'US',
        }

        for (const field of Object.keys(billingAddress)) {
          if (!billingAddress[field] && field !== 'addressLine2') {
            return triggerError('Please fill out all fields')
          }
        }

        cardFields.submit({ billingAddress })
      })
    }

    document
      .getElementById('card-field-submit-button')
      .addEventListener('click', submitCardFields)
  }
})
