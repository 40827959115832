function openPopupWindow(popupSelector) {
  $('.popup').fadeOut()

  $('.overlay').fadeIn()
  $(popupSelector).fadeIn()
}

function openRemotePopupWindow(url) {
  $.get(url, function (result) {
    const container = $(result)
    $('.popups').append(container)

    const popup = container.hasClass('popup')
      ? container
      : container.find('.popup')

    openPopupWindow(popup)
  })
}

/**
 * @param obj It could be clicked object or jquery selector
 */
function closePopupWindow(obj) {
  const popup = $(obj).closest('.popup')
  if (popup.length) {
    $('.overlay').fadeOut()
    popup.fadeOut()
  }
}

function openContentPopup(content) {
  const key = 'd' + Math.floor(Math.random() * 1000)
  const template = `<div class="popup dialog" id="${key}">
            ${content}
            <div class="pt-20 ta-center">
                <button class="btn blue js-close-popup">OK</button>
            </div>
        </div>`

  $('.popups').append(template)

  openPopupWindow('#' + key)
}

function openConfirmPopup(content, okCallback, cancelCallback, context) {
  const key = 'd' + Math.floor(Math.random() * 1000)
  const okButton = $(`<button class="btn blue">OK</button>`).on(
    'click',
    function () {
      okCallback && okCallback.apply(context)
      closePopupWindow(this)
    }
  )
  const cancelButton = $(`<button class="btn mr-10">CANCEL</button>`).on(
    'click',
    function () {
      cancelCallback && cancelCallback.apply(context)
      closePopupWindow(this)
    }
  )

  const actionsContainer = $('<div class="pt-20 ta-center"></div>').append(
    cancelButton,
    okButton
  )

  const template = $(
    `<div class="popup popup-fixed dialog" id="${key}">${content}</div>`
  )

  template.append(actionsContainer)

  $('.popups').append(template)

  openPopupWindow('#' + key)
}

$(() => {
  $('.overlay').on('click', function () {
    if (!$('.popup-fixed').length) {
      $('.popup').fadeOut()

      $('.overlay').fadeOut()
    }
  })

  $('.js-content-popup').each(function (i, o) {
    openContentPopup($(o).html())
  })

  $(document).on('click', '.popup .close', function () {
    return closePopupWindow(this)
  })
  $(document).on('click', '.js-close-popup', function () {
    return closePopupWindow(this)
  })

  $(document).on('click', '.js-remote-popup', function (e) {
    e.preventDefault()

    const url = $(this).data('url')

    openRemotePopupWindow(url)
  })
})

module.exports = {
  openPopupWindow,
  openRemotePopupWindow,
  closePopupWindow,
  openContentPopup,
  openConfirmPopup,
}
