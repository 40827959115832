$(() => {
  const item = $('.gift-item')
  const control = $('.control')
  const none = control.find('.blue')
  const submit = control.find('.green')
  const hidden = $("<input type='hidden' name='gift' class='selectedGift'>")
  const selectedGift = $('.selectedGift')

  function toDefaultState() {
    item.removeClass('selected').find('.btn').html('Select')
    selectedGift.remove()
    submit.hide()
    none.show()
  }

  function selectOne(obj) {
    $(obj).addClass('selected').find('.btn').html('Included')
    control.append(hidden.val($(obj).data('id')))
    submit.show()
    none.hide()
  }

  item.on('click', function () {
    if ($(this).hasClass('unavailable')) {
      return false
    }
    const isSelected = $(this).hasClass('selected')
    toDefaultState()
    if (!isSelected) {
      selectOne(this)
    }
  })
})
