function debounce(func, wait) {
  let timeout
  return function () {
    const context = this,
      args = arguments
    clearTimeout(timeout)
    timeout = setTimeout(function () {
      func.apply(context, args)
    }, wait)
  }
}

function basename(filename) {
  if (!filename?.length) {
    return ''
  }
  return filename.replace(/(.*)[\/\\]/g, '')
}

function showStatusMessage(message, theme, lifetime = 5000) {
  $.jGrowl(message, { theme: theme, life: lifetime })
}

function showErrorMessage(message) {
  return showStatusMessage(message, 'error')
}

function showSuccessMessage(message) {
  return showStatusMessage(message, 'success')
}

function createCookie(name, value, days) {
  let expires = ''
  if (days) {
    const date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = '; expires=' + date.toUTCString()
  }
  document.cookie = name + '=' + value + expires + '; path=/'
}

function getCookie(name) {
  const value = '; ' + document.cookie
  const parts = value.split('; ' + name + '=')

  if (parts.length === 2) {
    return parts.pop().split(';').shift()
  }

  return null
}

module.exports = {
  debounce,
  basename,
  createCookie,
  getCookie,
  showErrorMessage,
  showSuccessMessage,
  showStatusMessage,
}
