const { showErrorMessage, showSuccessMessage } = require('../../functions')

$(() => {
  const items = {}
  const root = '.bulk-upload '
  const linkInput = $(root + '.link-upload input:text')
  const linkSubmit = $(root + '.link-upload input:submit')
  const fileSelect = $(root + 'input:file')
  const dropBox = $(root + '.drop-files')
  const selectedItems = $(root + '.selected-files')
  const uploadedItems = $(root + '.upload-ready-items')
  const startUploadBtn = $(root + '.start-upload')
  const uploadStatus = $(root + ' .upload-status')
  const bulkAdd = $(root + ' .bulk-add')
  const itemTemplate =
    '<tr class="item"><td>%file%</td><td colspan="2"><div class="progress-bar hide"><div style="width: 0"></div></div><span>&nbsp;</span><a href="javascript:void(0)" class="f-right js-remove" data-name="%file%"><img src="/img/uploader/remove-item.png"/></a></td></tr>'

  function stopDefaultEvents(e) {
    e.preventDefault()
    e.stopPropagation()
  }

  function toggle() {
    ;(function toggleContainer() {
      const qnt = selectedItems.find('tr').length
      if (qnt) {
        return selectedItems.show()
      }
      return selectedItems.hide()
    })()
    ;(function toggleUploadedItems() {
      const qnt = uploadedItems.find('tr').length
      if (qnt) {
        return uploadedItems.show()
      }
      return uploadedItems.hide()
    })()
    ;(function toggleUploadBtn() {
      startUploadBtn.hide()
      $.each(items, function () {
        startUploadBtn.show()
      })
    })()
    ;(function toggleDropBox() {
      dropBox.removeClass('js-busy')
      fileSelect.prop('disabled', false)
      $.each(items, function () {
        if (this.uploading) {
          dropBox.addClass('js-busy')
          fileSelect.prop('disabled', 'disabled')
        }
      })
    })()
    ;(function toggleUploadStatus() {
      uploadStatus.hide()
      $.each(items, function () {
        if (this.uploading) {
          return uploadStatus.show()
        }
      })
    })()
    ;(function toggleSubmitBtn() {
      bulkAdd.hide()
      if (uploadedItems.find('tr').length) {
        bulkAdd.show()
      }
    })()
  }

  function toggleLinkSubmit(e) {
    linkSubmit.prop('disabled', 'disabled')
    if ($(e.target).val().length) {
      linkSubmit.prop('disabled', false)
    }
  }

  function onAmountChanged(e) {
    const el = e.target
    if (!el.min) {
      return
    }
    if (parseInt(el.value) < parseInt(el.min)) {
      el.value = el.min
    }
  }

  function addCartItem() {
    const self = this
    const id = $(this).data('id')
    const amount = $(this).closest('tr').find('input').val()
    const data = $('.constructor-options').find('select,input').serialize()
    const params = data + '&' + $.param({ amount: amount, frontFileId: id })

    function flushItem(result) {
      if (result.error) {
        return showErrorMessage(result.error)
      }
      if (result.success) {
        self.closest('tr').remove()
        toggle()
        return showSuccessMessage('Design was added')
      }
      return showErrorMessage('An error was occured')
    }

    $.post('/cart/add/', params, flushItem, 'json')
  }

  function addSelectedFile(e) {
    const files = e.target.files.length
      ? e.target.files
      : e.originalEvent.dataTransfer.files
    const types = sizeConfig.extensions
    $.each(files, function () {
      if (items[this.name] !== undefined) {
        return
      }
      const ext = this.name.split('.').pop().toLowerCase()
      if (types.indexOf(ext) === -1) {
        return showErrorMessage(this.name + ': unsupported file format')
      }
      const item = {
        file: this,
        template: $(itemTemplate.replace(/%file%/g, this.name)),
        uploading: false,
      }
      items[this.name] = item
      selectedItems.find('.upload-items').prepend(item.template)
    })

    fileSelect.val('')

    toggle()
  }

  function removeSelectedFile() {
    const id = $(this).data('id')
    const name = $(this).data('name')
    if (!name) {
      return false
    }

    delete items[name]

    $(this).closest('tr').remove()
    if (id) {
      $.getJSON('/file/remove/', { id: id })
    }

    toggle()
  }

  function startUpload() {
    const queue = []
    let i = 0
    const max = 5

    uploadStatus.show()
    startUploadBtn.hide()
    dropBox.addClass('js-busy')
    fileSelect.prop('disabled', 'disabled')

    function process(item) {
      const progress = item.template.find('.progress-bar')
      const removeBtn = item.template.find('.js-remove')

      if (item.uploading) {
        return
      }

      function loadStart() {
        progress.show()
        removeBtn.hide()
        item.uploading = true
      }

      function loadProgress(e) {
        const percent = (100 / e.total) * e.loaded
        const progressBar = progress.find('div')

        progressBar.animate({ width: percent + '%' }, 100)
      }

      function loadRetinaImages(container) {
        const images = $(container).find('img')
        if (!Retina.isRetina() || !images.length) {
          return
        }
        $.each(images, function () {
          new RetinaImage(this)
        })
      }

      function loadFilePreview(id) {
        item.template.find('span').html('Generating preview ...')
        $.getJSON(
          '/file/preview/',
          { id: id, product: sizeConfig.product },
          function (response) {
            if (response.error !== undefined) {
              showErrorMessage(response.error)
            }
            if (response.preview) {
              item.template.remove()
              loadRetinaImages(response.preview)
              uploadedItems.append(response.preview)
            }
            toggle()
          }
        )
      }

      function loadComplete() {
        if (xhr.readyState !== 4 || xhr.status !== 200) {
          return
        }
        progress.hide()
        item.uploading = false
        if (queue.length > 0) {
          process(queue.shift())
        }
        const response = JSON.parse(xhr.responseText)
        delete items[item.file.name]
        if (response.id) {
          return loadFilePreview(response.id)
        }
        if (response.error) {
          showErrorMessage(response.error)
        }
        item.template.remove()
        toggle()
      }

      const xhr = new XMLHttpRequest()
      xhr.addEventListener('loadstart', loadStart)
      xhr.addEventListener('readystatechange', loadComplete)
      xhr.upload.addEventListener('progress', loadProgress)

      const data = new FormData()
      data.append('file', item.file)
      xhr.open('POST', '/file/upload/')
      xhr.send(data)
    }

    $.each(items, function () {
      i < max ? process(this) : queue.push(this)
      ++i
    })
  }

  $('.bulk-upload').on('click', '.js-cart-add', addCartItem)
  $('.bulk-upload').on('click', '.js-remove', removeSelectedFile)
  $('.bulk-upload').on('click', '.start-upload', startUpload)
  $('.bulk-upload').on(
    'change',
    '.upload-ready-items input.qnt',
    onAmountChanged
  )
  fileSelect.on('change', addSelectedFile)
  linkInput.on('keyup', toggleLinkSubmit)

  dropBox.on(
    'drag dragstart dragend dragover dragenter dragleave drop',
    stopDefaultEvents
  )
  dropBox.on('drop', addSelectedFile)
})
